import React, { useEffect, useState } from 'react'
import { Badge } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import Cronr from 'cronr'
import { history } from 'index'
// import List2 from 'components/kit/widgets/Lists/2'
import styles from './style.module.scss'

const Actions = () => {
  const dispatch = useDispatch()
  const userUnit = useSelector(state => state.user.unit ? state.user.unit.id : null)
  const notificationsList = useSelector(state => state.schedules.notifications)
  const cancelsList = useSelector(state => state.cancels.list)
  const [totalNotifications, setTotalNotifications] = useState(0)

  useEffect(() => {
    const pattern = '* */5 * * * *'
    const cb = () => { 
      setTotalNotifications(0)
      dispatch({
        type: "schedules/NOTIFICATIONS",
        payload: {
          date: moment().subtract(5, "days").format('YYYY-MM-DD')
        }
      })
      dispatch({
        type: "cancels/LIST",
        payload: {
          date: moment().subtract(5, "days").format('YYYY-MM-DD')
        }
      })
    }
    const job = new Cronr(pattern, cb, {
      startTime: new Date(),
    })
    job.start()
    return function cleanup() {
      job.stop()
    }
  }, [])


  useEffect(() => {
    if (notificationsList) {
      if (userUnit) {
        const count = notificationsList.filter(n => n.child.unit === userUnit).length
        setTotalNotifications(totalNotifications + count)  
      } else {
        setTotalNotifications(totalNotifications + notificationsList.length)  
      }
    }
  }, [notificationsList])

  useEffect(() => {
    if (cancelsList) {
      if (userUnit) {
        console.log(cancelsList)
        const count = cancelsList.filter(n => n.child.unit === userUnit).length
        setTotalNotifications(totalNotifications + count)  
      } else {
        setTotalNotifications(totalNotifications + cancelsList.length)  
      }
    }
  }, [cancelsList])

  /*
  const menu = (
    <React.Fragment>
      <div className="card cui__utils__shadow width-350 border-0">
        <div className="card-body p-0">
          <List2 />
        </div>
      </div>
    </React.Fragment>
  ) */
  return (
//    <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
//    <div className={styles.dropdown}>
    <a href='#' onClick={e => { e.preventDefault(); history.push('/schedule/notifications') }}>
      <Badge count={totalNotifications}>
        <i className={`${styles.icon} fe fe-bell`} />
      </Badge>
    </a>
//    </Dropdown>
  )
}

export default Actions
