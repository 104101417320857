import localeAntd from 'antd/es/locale/en_US'

const messages = {
  'login.welcome': 'Bem-vindo ao',
  'login.message': 'Conecte-se com seu usuário',
  'login.email': 'Email',
  'login.email.message': 'Por favor digite seu usuário ou email',
  'login.pwd': 'Senha',
  'login.pwd.message': 'Por favor digite sua senha',
  'login.signin.button': 'Entrar',
  'login.forgot': 'Esqueceu a senha?',
  'forgot.message': 'Recuperar a Senha',
  'forgot.email': 'Endereço de Email',
  'forgot.email.message': 'Por favor digite seu endereço de e-mail',
  'forgot.button': 'Recuperar minha senha',
  'forgot.goback': 'Voltar para o login',
  'topBar.actions': 'Ações',
  'topBar.status': 'Status',
  'topBar.profileMenu.hello': 'Olá',
  'topBar.profileMenu.role': 'Papel',
  'topBar.profileMenu.email': 'Email',
  'topBar.profileMenu.phone': 'Celular',
  'topBar.profileMenu.editProfile': 'Editar Perfil',
  'topBar.profileMenu.logout': 'Sair',
  'topBar.unit': 'Unidade',
  'language.portuguese': 'Portugues',
  'language.english': 'Inglês',
  'language.japanease': 'Japonês',
  'user.name': 'Nome',
  'user.email': 'Email',
  'user.phone': 'Telefone',
  'user.role': 'Papel',
  'user.status': 'Status',
  'user.status.blocked': 'Bloqueado',
  'user.status.active': 'Ativo',
  'user.action': 'Ações',
  'user.edit': 'Editar',
  'user.block': 'Bloquear',
  'user.list.title': 'CONFIGURAÇÃO: Usuários',
  'user.new': 'Novo',
  'user.form.title': 'CONFIGURAÇÃO: Usuário',
  'user.child': 'Criança',
  'user.unit': 'Unidade',
  'user.blocked': 'Bloqueado',
  'user.form.select': 'Selecione',
  'user.form': 'Formulário',
  'user.form.save': 'Salvar',
  'user.form.clean': 'Limpar',
  'user.form.cancel': 'Cancelar',
  'unit.name': 'Nome',
  'unit.spaces': 'Salas',
  'unit.action': 'Ações',
  'unit.edit': 'Editar',
  'unit.list.title': 'CONFIGURAÇÃO: Unidades',
  'unit.new': 'Nova',
  'unit.form.title': 'CONFIGURAÇÃO: Unidade',
  'unit.email': 'Email',
  'unit.phone': 'Telefone',
  'unit.form.select': 'Selecione',
  'unit.start': 'Início',
  'unit.end': 'Fim',
  'unit.day': 'Dia',
  'unit.form.monday': 'Segunda',
  'unit.form.tuesday': 'Terça',
  'unit.form.wednesday': 'Quarta',
  'unit.form.thursday': 'Quinta',
  'unit.form.friday': 'Sexta',
  'unit.form.saturday': 'Sábado',
  'unit.form.sunday': 'Domingo',
  'unit.form': 'Formulário',
  'unit.form.save': 'Salvar',
  'unit.form.clean': 'Limpar',
  'unit.form.cancel': 'Cancelar',
  'syndrome.name': 'Diagnóstico',
  'syndrome.action': 'Ações',
  'syndrome.edit': 'Editar',
  'syndrome.delete': 'Apagar',
  'syndrome.delete.message': 'Tem certeza que deseja remover esse item?',
  'syndrome.list.title': 'CONFIGURAÇÃO: Diagnósticos',
  'syndrome.new': 'Novo',
  'syndrome.form.title': 'CONFIGURAÇÃO: Diagnóstico',
  'syndrome.form': 'Formulário',
  'syndrome.form.save': 'Salvar',
  'syndrome.form.clean': 'Limpar',
  'syndrome.form.cancel': 'Cancelar',
  'specialist.name': 'Nome',
  'specialist.email': 'Email',
  'specialist.phone': 'Telefone',
  'specialist.action': 'Ações',
  'specialist.edit': 'Editar',
  'specialist.delete': 'Apagar',
  'specialist.delete.message': 'Tem certeza que deseja remover esse item?',
  'specialist.list.title': 'CONFIGURAÇÃO: Terapeutas',
  'specialist.new': 'Novo',  
  'specialist.form.title': 'CONFIGURAÇÃO: Terapeuta',
  'specialist.birthday': 'Nascimento',
  'specialist.profession': 'Especialidade',
  'specialist.address': 'Endereço',
  'specialist.unit': 'Unidade',
  'specialist.celular': 'Celular',
  'specialist.form.select': 'Selecione',
  'specialist.start': 'Início',
  'specialist.end': 'Fim',
  'specialist.day': 'Dia',
  'specialist.form.newday': 'Novo dia',
  'specialist.form.monday': 'Segunda',
  'specialist.form.tuesday': 'Terça',
  'specialist.form.wednesday': 'Quarta',
  'specialist.form.thursday': 'Quinta',
  'specialist.form.friday': 'Sexta',
  'specialist.form.saturday': 'Sábado',
  'specialist.form.sunday': 'Domingo',
  'specialist.form': 'Formulário',
  'specialist.form.save': 'Salvar',
  'specialist.form.clean': 'Limpar',
  'specialist.form.cancel': 'Cancelar',
  'profession.name': 'Nome',
  'profession.action': 'Ações',
  'profession.edit': 'Editar',
  'profession.delete': 'Apagar',
  'profession.delete.message': 'Tem certeza que deseja remover esse item?',
  'profession.list.title': 'CONFIGURAÇÃO: Especialidades',
  'profession.new': 'Nova',  
  'profession.form.title': 'CONFIGURAÇÃO: Especialidade',
  'profession.form': 'Formulário',
  'profession.form.save': 'Salvar',
  'profession.form.clean': 'Limpar',
  'profession.form.cancel': 'Cancelar',
  'child.name': 'Nome',
  'child.father': 'Pai',
  'child.mother': 'Mãe',
  'child.email': 'Email',
  'child.phone': 'Telefone',
  'child.action': 'Ações',
  'child.edit': 'Editar',
  'child.delete': 'Apagar',
  'child.delete.message': 'Tem certeza que deseja remover esse item?',
  'child.list.title': 'CONFIGURAÇÃO: Crianças',
  'child.new': 'Nova',  
  'child.form.title': 'CONFIGURAÇÃO: Criança',
  'child.birthday': 'Nascimento',
  'child.social_name': 'Nome social',
  'child.address': 'Endereço',
  'child.unit': 'Unidade',
  'child.celular': 'Celular',
  'child.form.select': 'Selecione',
  'child.female': 'Feminino',
  'child.sex': 'Sexo',
  'child.male': 'Masculino',
  'child.syndrome': 'Diagnóstico',
  'child.situation': 'Situação',
  'child.school': 'Escola',
  'child.school_type': 'Tipo da escola',
  'child.class_type': 'Tipo da classe',
  'child.father_celular': 'Celular do pai',
  'child.mother_celular': 'Celular da mãe',
  'child.form.emergency': 'Contatos de Emergência',
  'child.father_workplace': 'Local de trabalho do pai',
  'child.father_workphone': 'Telefone do trabalho do pai',
  'child.mother_workplace': 'Local de trabalho da mãe',
  'child.mother_workphone': 'Telefone do trabalho da mãe',
  'child.class_normal': 'Normal',
  'child.class_special': 'Especial',
  'child.class_normal_special': 'Normal com sala especial',
  'child.maternal': 'Maternal',
  'child.jardim': 'Jardim',
  'child.fundamental': 'Fundamental',
  'child.medio': 'Ensino Médio',
  'child.form': 'Formulário',
  'child.form.save': 'Salvar',
  'child.form.clean': 'Limpar',
  'child.form.cancel': 'Cancelar',
}

export default {
  locale: 'en-US',
  localeAntd,
  messages,
}
