const actions = {
  SET_STATE: 'schedules/SET_STATE',
  LIST: 'schedules/LIST',
  DETAILS: 'schedules/DETAILS',
  UPDATE: 'schedules/UPDATE',
  CREATE: 'schedules/CREATE',
  DELETE: 'schedules/DELETE',
  NOTIFICATIONS: 'schedules/NOTIFICATIONS',
  CONFIRM: 'schedules/CONFIRM',
}

export default actions
