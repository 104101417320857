import localeAntd from 'antd/es/locale/en_US'


const messages = {
  'login.welcome': 'ようこそ',
  'login.message': 'サインインする',
  'login.email': 'メールアドレス',
  'login.email.message': 'ユーザー名またはメールアドレスを入力してください',
  'login.pwd': 'パスワード',
  'login.pwd.message': 'パスワードを入力してください',
  'login.signin.button': '入る',
  'login.forgot': 'パスワードをお忘れですか？',
  'forgot.message': 'パスワードのリセット',
  'forgot.email': 'メールアドレスをお忘れですか？',
  'forgot.email.message': 'メールアドレスを入力してください',
  'forgot.button': 'パスワードをリセットする',
  'forgot.goback': 'サインイン画面に戻る',
  'topBar.actions': '設定オプション',
  'topBar.status': '状態',
  'topBar.profileMenu.hello': 'こんにちは',
  'topBar.profileMenu.role': '職業',
  'topBar.profileMenu.email': 'メールアドレス',
  'topBar.profileMenu.phone': '電話番号',
  'topBar.profileMenu.editProfile': '情報を編集する',
  'topBar.profileMenu.logout': 'ログアウト',
  'topBar.unit': '校舎',
  'language.portuguese': 'ポルトガル語',
  'language.english': '英語',
  'language.japanease': '日本語',
  'login.password': 'パスワード',
  'user.name': '名前',
  'user.email': 'メールアドレス',
  'user.phone': '電話番号',
  'user.role': '職業',
  'user.status': '状態',
  'user.status.blocked': 'ユーザーはブロックされました',
  'user.status.active': 'アクティブ',
  'user.action': '設定オプション',
  'user.edit': '編集する',
  'user.block': 'ブロックする',
  'user.list.title': 'ユーザーの設定',
  'user.list.search': '探す',
  'user.list.clean': 'リセット',
  'user.new': '新しいユーザー',
  'user.form.title': 'ユーザーの設定',
  'user.child': 'お子様',
  'user.unit': '校舎',
  'user.blocked': 'ブロックされました。',
  'user.form.select': '選択してください',
  'user.form': 'フォーム',
  'user.form.save': '保存する',
  'user.form.clean': 'リセット',
  'user.form.cancel': 'キャンセル',
  'unit.name': '名前',
  'unit.spaces': '教室',
  'unit.action': '設定オプション',
  'unit.edit': '編集する',
  'unit.list.title': '校舎の設定',
  'unit.new': '新しい校舎',
  'unit.form.title': 'フォームの設定',
  'unit.email': 'メールアドレス',
  'unit.phone': '校舎の電話番号',
  'unit.form.select': '選択する',
  'unit.start': '開始時間',
  'unit.end': '終了時間',
  'unit.day': '朝',
  'unit.form.monday': '月曜日',
  'unit.form.tuesday': '火曜日',
  'unit.form.wednesday': '水曜日',
  'unit.form.thursday': '木曜日',
  'unit.form.friday': '金曜日',
  'unit.form.saturday': '土曜日',
  'unit.form.sunday': '日曜日',
  'unit.form': 'フォーム',
  'unit.form.save': '保存する',
  'unit.form.clean': 'リセット',
  'unit.form.cancel': 'キャンセル',
  'syndrome.name': '診断',
  'syndrome.action': '設定オプション',
  'syndrome.edit': '編集する',
  'syndrome.delete': '消す',
  'syndrome.delete.message': 'この診断を消してもよろしいですか？',
  'syndrome.list.title': '診断の設定',
  'syndrome.new': '新しい診断',
  'syndrome.form.title': '診断の設定',
  'syndrome.form': 'フォーム',
  'syndrome.form.save': '保存する',
  'syndrome.form.clean': 'リセット',
  'syndrome.form.cancel': 'キャンセル',
  'specialist.name': '名前',
  'specialist.email': 'メールアドレス',
  'specialist.phone': '電話番号',
  'specialist.action': '設定オプション',
  'specialist.edit': '編集する',
  'specialist.delete': '削除する',
  'specialist.delete.message': 'この療法士を消してもよろしいですか？',
  'specialist.list.title': '療法士の設定',
  'specialist.new': '新しい療法士',  
  'specialist.form.title': '療法士の設定',
  'specialist.birthday': '生年月日',
  'specialist.profession': '専門',
  'specialist.address': '住所',
  'specialist.unit': '校舎',
  'specialist.celular': '電話番号',
  'specialist.form.select': '選択する',
  'specialist.start': '開始時間',
  'specialist.end': '終了時間',
  'specialist.day': '日',
  'specialist.form.newday': '新しい日',
  'specialist.form.monday': '月曜日',
  'specialist.form.tuesday': '火曜日',
  'specialist.form.wednesday': '水曜日',
  'specialist.form.thursday': '木曜日',
  'specialist.form.friday': '金曜日',
  'specialist.form.saturday': '土曜日',
  'specialist.form.sunday': '日曜日',
  'specialist.form': 'フォーム',
  'specialist.form.save': '保存する',
  'specialist.form.clean': 'リセット',
  'specialist.form.cancel': 'キャンセル',
  'profession.name': '専門',
  'profession.action': '設定オプション',
  'profession.edit': '編集する',
  'profession.delete': '削除',
  'profession.delete.message': 'この専門を削除してもよろしいですか？',
  'profession.list.title': '専門の設定',
  'profession.new': '新しい専門',  
  'profession.form.title': '専門の設定',
  'profession.form': 'フォーム',
  'profession.form.save': '保存する',
  'profession.form.clean': 'リセット',
  'profession.form.cancel': 'キャンセル',
  'child.name': '名前',
  'child.name.jp': '日本語名',
  'child.father': '父',
  'child.mother': '母',
  'child.email': 'メールアドレス',
  'child.phone': '電話番号',
  'child.action': '設定オプション',
  'child.edit': '編集する',
  'child.delete': '削除する',
  'child.delete.message': 'このアイテムを削除してもよろしいですか？',
  'child.list.title': 'お子様のプロフィール設定',
  'child.new': '新しいお子様',  
  'child.form.title': 'お子様のプロフィール設定',
  'child.birthday': '生年月日',
  'child.social_name': '名前',
  'child.address': '住所',
  'child.address.jp': '住所（日本語）',
  'child.unit': '校舎',
  'child.celular': '電話番号',
  'child.form.select': '選択する',
  'child.female': '女子',
  'child.sex': '性別',
  'child.male': '男子',
  'child.syndrome': '診断',
  'child.situation': '学齢／学年',
  'child.school': '学校',
  'child.school_type': '学校種別',
  'child.class_type': 'クラスタイプ',
  'child.father_celular': '父の電話番号',
  'child.mother_celular': '母の電話番号',
  'child.form.emergency': '緊急連絡先',
  'child.father_workplace': '父の職場',
  'child.father_workphone': '父の事業用の電話番号',
  'child.mother_workplace': '母の職場',
  'child.mother_workphone': '母の事業用の電話番号',
  'child.class_normal': '正常',
  'child.class_special': '特別',
  'child.class_normal_special': '正常で特別なクラス',
  'child.maternal': '保育園',
  'child.jardim': '幼稚園',
  'child.fundamental': '小学校',
  'child.ginasio': '中学校',
  'child.medio': '高校',
  'child.form': 'フォーム',
  'child.form.save': '保存する',
  'child.form.clean': 'リセット',
  'child.form.cancel': 'キャンセル',
  'child.day': '日',
  'child.start': '開始時間',
  'child.end': '終了時間',
  'child.form.monday': '月曜日',
  'child.form.tuesday': '火曜日',
  'child.form.wednesday': '水曜日',
  'child.form.thursday': '木曜日',
  'child.form.friday': '金曜日',
  'child.form.saturday': '土曜日',
  'child.form.sunday': '日曜日',
  'child.profession': '専門家',
  'child.tab1': 'お子様の個人情報',
  'child.tab2': '学齢／学年',
  'child.tab3': '保護者について',
  'child.tab4': 'スケジュール',
  'child.category': '部門',
  'child.category1': '児童発達支援',
  'child.category7': '放課後等デイサービス',
  'schedule.list.title': 'スケジュール',
  'schedule.list.new': '予定を追加する',  
  'schedule.form.title': '追加された予定',
  'schedule.child': 'お子様',
  'schedule.unit': '校舎',
  'schedule.start': '開始時間',
  'schedule.end': '終了時間',
  'schedule.day': '日付',
  'schedule.open': '予約状況',
  'schedule.canceled': 'キャンセルされた',
  'schedule.confirmed': '決定された',
  'schedule.form': 'フォーム',
  'schedule.form.save': '保存',
  'schedule.form.clean': 'リセット',
  'schedule.form.cancel': 'キャンセル',
  'schedule.form.newchild': 'お子様を追加する',
  'schedule.toolbar.today': '今日',
  'schedule.toolbar.month': '月',
  'schedule.toolbar.week': '週',
  'schedule.toolbar.day': '日',
  'schedule.toolbar.agenda': 'スケジュール',
  "notifications.list.title": "スケジュール通知",
  'notifications.child': 'お子様',
  'notifications.unit': '校舎',
  'notifications.data': '日付',
  'notifications.start': '開始時間',
  'notifications.reason': 'メッセージ',
  'notifications.confirmed': '決定',
  'notifications.waiting': '未決',
  'notifications.canceled': 'キャンセルされた',
  'notifications.confirm': '決定する',
  'notifications.yes': 'はい',
  'notifications.no': 'いいえ',
  'notifications.ask': "予定は決定しますか？",
  'notifications.confirm.message.title': '予定は決定されました！',
  'notifications.confirm.message.text': '其れ其れの支援はお子様の成長に貢献することにご注意ください。',
  'notifications.opensession.message.title': '追加の予約は可能です。',
  'messages.list.title': '報告のメッセージ',
  'messages.new': '新しいメッセージ',  
  'messages.action': '設定オプション',
  'messages.edit': '編集する',
  'messages.delete': '削除する',
  'messages.delete.message': 'このアイテムを削除してもよろしいですか？',
  'messages.start': '開始',
  'messages.end': '終わり',
  'messages.type': 'タイプ',
  'messages.title': '題名',
  'messages.form': 'フォーム',
  'messages.form.save': '保存',
  'messages.form.clean': 'リセット',
  'messages.form.cancel': 'キャンセル',
  'messages.form.title': '報告のメッセージ',
  'messages.form.unit': '校舎',
  'messages.form.child': 'お子様の',
  'messages.form.text': '文章',
  'messages.form.syndrome': '診断',
  'messages.form.all': '全て',
  'messages.form.category': '部門',
  'messages.form.category1': '児童発達支援',
  'messages.form.category7': '放課後等デイサービス',
  'messages.form.sessions': '毎週のスケジュール',
}


export default {
  locale: 'ja-JP',
  localeAntd,
  messages,
}