import { apiClient } from 'services/axios'
import store from 'store'

export async function login(identifier, password) {
  store.remove('accessToken')
  return apiClient
    .post('/auth/local', {
      identifier,
      password,
    })
    .then(response => {
      if (response) {
        const { jwt } = response.data
        if (jwt) {
          store.set('accessToken', jwt)
        }
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function register(email, password, name) {
  return apiClient
    .post('/auth/local/register', {
      email,
      password,
      name,
    })
    .then(response => {
      if (response) {
        const { accessToken } = response.data
        if (accessToken) {
          store.set('accessToken', accessToken)
        }
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function currentAccount() {
  return apiClient
    .get('/users/me')
    .then(response => {
      if (response) {
        const { accessToken } = response.data
        if (accessToken) {
          store.set('accessToken', accessToken)
        }
        const userData = {}
        userData.id = response.data.id
        userData.name = response.data.username
        userData.email = response.data.email
        userData.avatar = response.data.avatar
        userData.role = response.data.role
        userData.unit = response.data.unit
        userData.authorized = !response.data.blocked && (response.data.role.type === "staff" || response.data.role.type === "admin")
        return userData
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function logout() {
//  return apiClient
//    .get('/auth/local/logout')
//    .then(() => {
      store.remove('accessToken')
      return true
//    })
//    .catch(err => console.log(err))
}
