import axios from 'axios'
import store from 'store'
import { notification } from 'antd'

export const apiClient = axios.create({
  // baseURL: `${window.location.protocol}//${window.location.hostname}:1337`,
   baseURL: 'http://kirariapi.imtech.com.br:1337',
  // timeout: 1000,
  // headers: { 'X-Custom-Header': 'foobar' }
})

export const pushClient = axios.create({
  // baseURL: `${window.location.protocol}//${window.location.hostname}:1337`,
   baseURL: 'https://fcm.googleapis.com/v1/projects/945382354537',
  // timeout: 1000,
   headers: { 'Content-Type': 'application/json', 'Authorization': 'key=AIzaSyAAPMRm_QD5gRprrDlNpudJmX1tCoKV-RI' }
})


apiClient.interceptors.request.use(request => {
  const accessToken = store.get('accessToken')
  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`
    request.headers.AccessToken = accessToken
  }
  return request
})

apiClient.interceptors.response.use(undefined, error => {
  // Errors handling
  const { response } = error
  const { data } = response
  if (data) {
    if (response.status === 400) {
      notification.warning({
        message: data.message[0].messages[0].message,
      })  
    } else {
      notification.warning({
        message: data.message,
      })  
    }
  }
})
