import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import * as api from 'services/api'
import { history } from 'index'
import FCM from 'fcm-node'
import actions from './actions'

const serverKey = 'AAAA3B0t7mk:APA91bH4US7mYUhkO0DlayeZSdf937sLBiwGR_b2WsNzJ_1oNepvfm5zsWk_LkGMzsLTLmJIqz4a7LNk_zIYCsOyZ0Wds2OjDgB504FUoX4IYRt3Fjk2vVJXJkm3dTFBDpuX23GK7YAt'
const fcm = new FCM(serverKey)
const sendPush = (msgData) => {


  const message = { 
//    to: msgData.token,
//    to: 'fn9Esu6LQHiUfz29N7NxzM:APA91bFN7x9TSOrasaPWpCHCwlburyMwT54M3ElV_xiAA_1b1QbZEeXyESL3bfQk1b_rLjWAfKlWahNWbRWCGbsVukeROI5Q9WGRm7sEJ7zyFhzY94MWEZ2nQYydhuZd3N_eSnjeH1a3', 
  //  collapse_key: 'your_collapse_key',
    
    notification: {
        title: 'Nova mensagem de Kirari', 
        body: msgData.title
    },
    
  //  data: {  //you can send only notification or only data(or include both)
  //      my_key: 'my value',
  //      my_another_key: 'my another value'
  //  }
  }
  if (msgData.type === 'person') {
    message.to = msgData.token
  }
  if (msgData.type === 'unit') {
    message.to = `/topics/unit_${msgData.unit}`
  }
  if (msgData.type === 'syndrome') {
    message.to = `/topics/syndrome_${msgData.syndrome}`
  }
  if (msgData.type === 'category') {
    message.to = `/topics/category_${msgData.category}`
  }
  if (msgData.type === 'sessions') {
    message.to = `/topics/sessions_${msgData.sessions}`
  }
  fcm.send(message, function(err, response){
    if (err) {
        console.log("Something has gone wrong!", err);
    } else {
        console.log("Successfully sent with response: ", response);
    }
  })

  
}
export function* LIST() {
  yield put({
    type: 'messages/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(api.messageList, null)
  if (success) {
    yield put({
      type: 'messages/SET_STATE',
      payload: {
        list: success,
        loading: false,
      }
    })
  }
  if (!success) {
    yield put({
      type: 'messages/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* DETAILS({ payload }) {
  const { id } = payload
  yield put({
    type: 'messages/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(api.messageDetails, id)
  if (success) {
    yield put({
      type: 'messages/SET_STATE',
      payload: {
        selected: success,
        loading: false,
      }
    })
  }
  if (!success) {
    yield put({
      type: 'messages/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* UPDATE({ payload }) {
  const { id, messageData } = payload
  yield put({
    type: 'messages/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(api.updateMessage, id, messageData)
  if (success) {
    yield put({
      type: 'messages/SET_STATE',
      payload: {
        loading: false,
      }
    })
    yield history.push('/schedule/messages')
    notification.success({
      message: 'Success',
      description: 'Data saved!',
    })
  }
  if (!success) {
    yield put({
      type: 'messages/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* CREATE({ payload }) {
  const { messageData } = payload
  yield put({
    type: 'messages/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(api.createMessage, messageData)
  if (success) {
    sendPush(messageData)
    yield put({
      type: 'messages/SET_STATE',
      payload: {
        loading: false,
      }
    })
    yield history.push('/schedule/messages')
    notification.success({
      message: 'Success',
      description: 'Data saved!',
    })
  }
  if (!success) {
    yield put({
      type: 'messages/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}


export function* DELETE({ payload }) {
  const { id } = payload
  yield put({
    type: 'messages/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(api.deleteMessage, id)
  if (success) {
    yield put({
      type: 'messages/SET_STATE',
      payload: {
        loading: false,
      }
    })
    yield put({
      type: 'messages/LIST',
    })
    notification.success({
      message: 'Success',
      description: 'Deleted!',
    })
  }
  if (!success) {
    yield put({
      type: 'messages/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LIST, LIST),
    takeEvery(actions.DETAILS, DETAILS),
    takeEvery(actions.UPDATE, UPDATE),
    takeEvery(actions.CREATE, CREATE),
    takeEvery(actions.DELETE, DELETE),
  ])
}
