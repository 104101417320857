import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import user from './user/reducers'
import menu from './menu/reducers'
import settings from './settings/reducers'
import users from './users/reducers'
import units from './units/reducers'
import groups from './groups/reducers'
import children from './children/reducers'
import specialists from './specialists/reducers'
import professions from './professions/reducers'
import syndromes from './syndromes/reducers'
import messages from './messages/reducers'
import schedules from './schedules/reducers'
import cancels from './cancels/reducers'

export default history =>
  combineReducers({
    router: connectRouter(history),
    user,
    menu,
    settings,
    users,
    units,
    groups,
    children,
    specialists,
    professions,
    syndromes,
    messages,
    schedules,
    cancels
  })
