const actions = {
  SET_STATE: 'messages/SET_STATE',
  LIST: 'messages/LIST',
  DETAILS: 'messages/DETAILS',
  UPDATE: 'messages/UPDATE',
  CREATE: 'messages/CREATE',
  DELETE: 'messages/DELETE',
}

export default actions
