import moment from 'moment'
import { apiClient, pushClient } from 'services/axios'

export async function usersList(userUnit) {
  return apiClient
    .get('/users', {
        params: {
          unit_eq: userUnit ? userUnit.id : null,
          _limit: 2000
        }
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function userDetails(id) {
  return apiClient
    .get(`/users/${id}`, {
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function updateUser(id, userData) {
  return apiClient
    .put(`/users/${id}`, {
      ...userData
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function createUser(userData) {
  return apiClient
    .post('/users', {
      ...userData
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}


export async function unitsList(userUnit) {
  return apiClient
    .get('/units', {
      params: {
        id_eq: userUnit ? userUnit.id : null,
        _limit: 2000
      }
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function unitDetails(id) {
  return apiClient
    .get(`/units/${id}`, {
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function updateUnit(id, unitData) {
  return apiClient
    .put(`/units/${id}`, {
      ...unitData
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function createUnit(unitData) {
  return apiClient
    .post('/units', {
      ...unitData
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function groupsList() {
  return apiClient
    .get('/groups', {
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function groupDetails(id) {
  return apiClient
    .get(`/groups/${id}`, {
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function updateGroup(id, groupData) {
  return apiClient
    .put(`/groups/${id}`, {
      ...groupData
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function createGroup(groupData) {
  return apiClient
    .post('/groups', {
      ...groupData
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}


export async function childrenList(userUnit) {
  return apiClient
    .get('/children', {
      params: {
        unit_eq: userUnit ? userUnit.id : null,
        _limit: 2000
      }
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function childDetails(id) {
  return apiClient
    .get(`/children/${id}`, {
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function updateChild(id, childData) {
  return apiClient
    .put(`/children/${id}`, {
      ...childData
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function createChild(childData) {
  return apiClient
    .post('/children', {
      ...childData
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function specialistList(userUnit) {
  return apiClient
    .get('/specialists', {
      params: {
        unit_eq: userUnit ? userUnit.id : null,
        _limit: 2000
      }
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function specialistDetails(id) {
  return apiClient
    .get(`/specialists/${id}`, {
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function updateSpecialist(id, specialistData) {
  return apiClient
    .put(`/specialists/${id}`, {
      ...specialistData
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function createSpecialist(specialistData) {
  return apiClient
    .post('/specialists', {
      ...specialistData
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function syndromeList() {
  return apiClient
    .get('/syndromes', {
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function syndromeDetails(id) {
  return apiClient
    .get(`/syndromes/${id}`, {
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function deleteSyndrome(id) {
  return apiClient
    .delete(`/syndromes/${id}`, {
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function updateSyndrome(id, syndromeData) {
  return apiClient
    .put(`/syndromes/${id}`, {
      ...syndromeData
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function createSyndrome(syndromeData) {
  return apiClient
    .post('/syndromes', {
      ...syndromeData
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function professionList() {
  return apiClient
    .get('/professions', {
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function professionDetails(id) {
  return apiClient
    .get(`/professions/${id}`, {
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function deleteProfession(id) {
  return apiClient
    .delete(`/professions/${id}`, {
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function updateProfession(id, professionData) {
  return apiClient
    .put(`/professions/${id}`, {
      ...professionData
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function createProfession(professionData) {
  return apiClient
    .post('/professions', {
      ...professionData
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function scheduleList(userUnit) {
  return apiClient
    .get('/schedules', {
      params: {
        unit_eq: userUnit ? userUnit.id : null,
        _limit: 2000
      }
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function scheduleNotifications(userUnit, datetime) {
  return apiClient
    .get('/schedules?_sort=date:DESC', {
      params: {
        unit_eq: userUnit ? userUnit.id : null,
        date_gte: datetime,
        _limit: 2000
      }
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function scheduleDetails(id) {
  return apiClient
    .get(`/schedules/${id}`, {
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function deleteSchedule(id) {
  return apiClient
    .delete(`/schedules/${id}`, {
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function updateSchedule(id, scheduleData) {
  return apiClient
    .put(`/schedules/${id}`, {
      ...scheduleData
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function createSchedule(scheduleData) {
  return apiClient
    .post('/schedules', {
      ...scheduleData
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function messageList(userUnit) {
  return apiClient
    .get('/messages', {
      params: {
        unit_eq: userUnit ? userUnit.id : null,
        _limit: 2000
      }
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function messageDetails(id) {
  return apiClient
    .get(`/messages/${id}`, {
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function deleteMessage(id) {
  return apiClient
    .delete(`/messages/${id}`, {
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function updateMessage(id, messageData) {
  return apiClient
    .put(`/messages/${id}`, {
      ...messageData
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function createMessage(messageData) {
  return apiClient
    .post('/messages', {
      ...messageData
    })
    .then(response => {
      if (response) {
        pushClient.post('/messages:send', {
          message:{
            notification: {
              title: "Kirari Message",
              body: messageData.title,
          },  
          }
        }).then(resp => console.log(resp)).catch(e => console.log(e))
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function cancelList() {
  return apiClient
    .get('/cancels?_sort=session_timestamp:DESC', {
      params: {
        session_timestamp_gte: moment().format('YYYY-MM-DD'),
        _limit: 2000
      }
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function cancelDetails(id) {
  return apiClient
    .get(`/cancels/${id}`, {
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function deleteCancel(id) {
  return apiClient
    .delete(`/cancels/${id}`, {
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function updateCancel(id, cancelData) {
  return apiClient
    .put(`/cancels/${id}`, {
      ...cancelData
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function createCancel(cancelData) {
  return apiClient
    .post('/cancels', {
      ...cancelData
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}