import { all } from 'redux-saga/effects'
import user from './user/sagas'
import menu from './menu/sagas'
import settings from './settings/sagas'
import users from './users/sagas'
import units from './units/sagas'
import groups from './groups/sagas'
import children from './children/sagas'
import spacialists from './specialists/sagas'
import professions from './professions/sagas'
import syndromes from './syndromes/sagas'
import messages from './messages/sagas'
import schedules from './schedules/sagas'
import cancels from './cancels/sagas'

export default function* rootSaga() {
  yield all([user(), menu(), settings(), users(), units(), groups(), children(), spacialists(), professions(), syndromes(), messages(), schedules(), cancels()])
}
