const actions = {
  SET_STATE: 'professions/SET_STATE',
  LIST: 'professions/LIST',
  DETAILS: 'professions/DETAILS',
  UPDATE: 'professions/UPDATE',
  CREATE: 'professions/CREATE',
  DELETE: 'professions/DELETE',
}

export default actions
