import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import * as api from 'services/api'
import { history } from 'index'
import actions from './actions'

export function* LIST() {
  yield put({
    type: 'syndromes/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(api.syndromeList)
  if (success) {
    yield put({
      type: 'syndromes/SET_STATE',
      payload: {
        list: success,
        loading: false,
      }
    })
  }
  if (!success) {
    yield put({
      type: 'syndromes/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* DETAILS({ payload }) {
  const { id } = payload
  yield put({
    type: 'syndromes/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(api.syndromeDetails, id)
  if (success) {
    yield put({
      type: 'syndromes/SET_STATE',
      payload: {
        selected: success,
        loading: false,
      }
    })
  }
  if (!success) {
    yield put({
      type: 'syndromes/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* UPDATE({ payload }) {
  const { id, syndromeData } = payload
  yield put({
    type: 'syndromes/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(api.updateSyndrome, id, syndromeData)
  if (success) {
    yield put({
      type: 'syndromes/SET_STATE',
      payload: {
        loading: false,
      }
    })
    yield history.push('/database/syndromes')
    notification.success({
      message: 'Success',
      description: 'Data saved!',
    })
  }
  if (!success) {
    yield put({
      type: 'syndromes/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* CREATE({ payload }) {
  const { syndromeData } = payload
  yield put({
    type: 'syndromes/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(api.createSyndrome, syndromeData)
  if (success) {
    yield put({
      type: 'syndromes/SET_STATE',
      payload: {
        loading: false,
      }
    })
    yield history.push('/database/syndromes')
    notification.success({
      message: 'Success',
      description: 'Data saved!',
    })
  }
  if (!success) {
    yield put({
      type: 'syndromes/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* DELETE({ payload }) {
  const { id } = payload
  yield put({
    type: 'syndromes/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(api.deleteSyndrome, id)
  if (success) {
    yield put({
      type: 'syndromes/SET_STATE',
      payload: {
        loading: false,
      }
    })
    yield put({
      type: 'syndromes/LIST',
    })
    notification.success({
      message: 'Success',
      description: 'Deleted!',
    })
  }
  if (!success) {
    yield put({
      type: 'syndromes/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LIST, LIST),
    takeEvery(actions.DETAILS, DETAILS),
    takeEvery(actions.UPDATE, UPDATE),
    takeEvery(actions.CREATE, CREATE),
    takeEvery(actions.DELETE, DELETE),
  ])
}
