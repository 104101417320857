import { all, put, call, select, takeEvery } from 'redux-saga/effects'
import { getMenuDataPT, getMenuDataJP, getMenuDataEN }  from 'services/menu'
import actions from './actions'

export function* GET_DATA(action) {
  let language = yield select(state => state.settings.locale)
  if (action && action.payload) language = action.payload.locale
  let menuData = []
  if (language === 'pt-BR') menuData = yield call(getMenuDataPT)
  if (language === 'ja-JP') menuData = yield call(getMenuDataJP)
  if (language === 'en-US') menuData = yield call(getMenuDataEN)
  yield put({
    type: 'menu/SET_STATE',
    payload: {
      menuData,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_DATA, GET_DATA),
    GET_DATA(), // run once on app load to fetch menu data
  ])
}
