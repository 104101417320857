import React from 'react'
import { Menu, Dropdown } from 'antd'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import moment from "moment"
import 'moment/locale/pt'
import 'moment/locale/ja'
import styles from './style.module.scss'

const mapStateToProps = ({ settings }) => ({
  locale: settings.locale,
})

const LanguageSwitcher = ({ dispatch, locale }) => {
  const changeLanguage = ({ key }) => {
    if (key === 'ja-JP') {
      moment.locale('ja')
    } else {
      moment.locale('pt')
    }
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'locale',
        value: key,
      },
    })
    dispatch({
      type: 'menu/GET_DATA',
      payload: {
        locale: key,
      },
    })
  }
  const language = locale.substr(0, 2)
  const menu = (
    <Menu selectedKeys={[locale]} onClick={changeLanguage}>
      <Menu.Item key="pt-BR">
        <span className="text-uppercase font-size-12 mr-2">PT</span>
        <FormattedMessage id="language.portuguese" />
      </Menu.Item>
      <Menu.Item key="ja-JP">
        <span className="text-uppercase font-size-12 mr-2">JP</span>
        <FormattedMessage id="language.japanease" />
      </Menu.Item>
    </Menu>
  )

  return (
    <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
      <div className={styles.dropdown}>
        <span className="text-uppercase">{language}</span>
      </div>
    </Dropdown>
  )
}

export default connect(mapStateToProps)(LanguageSwitcher)
