const actions = {
  SET_STATE: 'syndromes/SET_STATE',
  LIST: 'syndromes/LIST',
  DETAILS: 'syndromes/DETAILS',
  UPDATE: 'syndromes/UPDATE',
  CREATE: 'syndromes/CREATE',
  DELETE: 'syndromes/DELETE',
}

export default actions
